<template>
  <v-list-item
    link
    :key="customer.uuid"
    @click="openCustomer"
  >
    <v-list-item-avatar color="primary">
      <span
        class="white--text"
        style="margin: 0 auto;"
      >{{ initials }}</span>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ name }}</v-list-item-title>
      <v-list-item-subtitle>
        <span v-if="customer.label"><span class="text--primary">{{ customer.label }}</span> &mdash;</span> {{ customer.phone }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="customer.deliverInvoice">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon color="green">
              mdi-email-send-outline
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('invoiceWillBeDelivered') }}</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action v-if="customer.invoiceable">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon color="green">
              mdi-text-box-check-outline
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('invoicable') }}</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action v-if="!customer.invoiceable && false">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon disabled>
              mdi-text-box-remove
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('cannotBeInvoiced') }}</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action v-if="customer.exclusive">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            :href="'mailto:' + customer.email"
          >
            <v-icon color="orange">
              mdi-star
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('exclusive') }}</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action v-else>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            :href="'mailto:' + customer.email"
          >
            <v-icon color="orange">
              mdi-star-outline
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('notExclusive') }}</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action v-if="false">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            :href="'mailto:' + customer.email"
          >
            <v-icon color="green lighten-1">
              mdi-email
            </v-icon>
          </v-btn>
        </template>
        <span>{{ customer.email }}</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action v-if="false">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            :href="'tel:' + customer.phone"
          >
            <v-icon color="green lighten-1">
              mdi-phone
            </v-icon>
          </v-btn>
        </template>
        <span>{{ customer.phone }}</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action v-if="false">
      <v-btn icon>
        <v-icon color="grey lighten-1">
          mdi-information
        </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  export default {
    name: 'CustomerListItem',
    props: {
      customer: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({

    }),
    computed: {
      name() {

        if(this.customer === null || this.customer.address === null || this.customer.address.name_line === null) {
          return this.$t('noName')
        }

        return this.customer.address.name_line
      },
      inIframe() {
        return this.$store.state.inIframe
      },
      initials() {

        if(this.customer === null || this.customer.address === null || this.customer.address.name_line === null) {
          return ''
        }

        return this.customer.address.name_line.getInitials()
      }
    },
    methods: {
      openCustomer() {
        this.$emit('click')
      }
    }
  }
</script>
